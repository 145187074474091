<template>
  <div
    class="no-requests__container flex-1 flex flex-col content-center justify-center h-full"
  >
    <p class="no-requests__text font-semibold text-center"></p>
  </div>
</template>

<style scoped>
.no-requests__container {
  background-color: var(--container-bg-color);
  box-shadow: none;
}

.no-requests__text {
  font-size: var(--fs-500);
}
</style>

export const methodAndAction = {
  eth_sign: 'Signature',
  personal_sign: 'Personal Signature',
  eth_signTypedData_v4: 'Signature',
  eth_sendTransaction: 'Send Transaction',
  eth_signTransaction: 'Transaction Signature',
  eth_decrypt: 'Request Decryption',
  wallet_addEthereumChain: 'Add Chain',
  wallet_switchEthereumChain: 'Switch Chain',
  wallet_watchAsset: 'Add Token',
}
